import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

function Bill() {
  const { BillId } = useParams();
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apireq05042024}CustomerTransactionMaster/GetBillReport/${BillId}`);
        // console.log(response.data);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [BillId, apireq05042024]);

  if (!data || !data.customerTransaction || !data.clothData) {
    return <p>Loading...</p>;
  }

  const { customerTransaction, clothData } = data;

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title" style={{ textAlign: 'center' }}>Rajesh Power Laundry, Karad</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <p>Tag No. {customerTransaction.tagno}</p>
                  <span>Status. {customerTransaction.status}</span>
                </div>
                <div className="col-6 text-end">
                  <p>Date: {new Date(customerTransaction.tran_date).toLocaleDateString('en-GB')}</p>
                  <span>Customer Name - {customerTransaction.customer_name}</span>
                </div>
              </div>
              <hr />
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th style={{ textAlign: 'center' }}>Qty</th>
                    <th style={{ textAlign: 'center' }}>Price (₹)</th>
                    <th style={{ textAlign: 'center' }}>Amount (₹)</th>
                  </tr>
                </thead>
                <tbody>
                  {clothData
                    .filter(cloth => cloth.qty > 0)
                    .map((cloth, index) => (
                      <tr key={index}>
                        <td>{cloth.cloth_type}</td>
                        <td style={{ textAlign: 'center' }}>{cloth.qty}</td>
                        <td style={{ textAlign: 'center' }}>{cloth.charges_rs}</td>
                        <td style={{ textAlign: 'center' }}>{cloth.amt}</td>
                      </tr>
                    ))}
                  <tr>
                    <td><b>Total Items:</b></td>
                    <td style={{ textAlign: 'center' }}><b>{clothData.reduce((total, cloth) => total + cloth.qty, 0)}</b></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <table className="table">
                <tr>
                  <td>Delivery Date: {new Date(customerTransaction.deliveryon).toLocaleDateString('en-GB')}</td>
                  <td>Total Amount: ₹{customerTransaction.dramt}</td>
                </tr>
                <tr>
                  <td>Jama Amt (Advance Payment): ₹{customerTransaction.cramt}</td>
                  <td>Bal (Balance): ₹{data.clbal}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bill;
