import React, { useEffect, useState, useRef  } from  'react';
import {useNavigate, json, Navigate} from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FaWhatsapp } from 'react-icons/fa';
import NavBarMain from './NavBarMain';
import { useMediaQuery } from 'react-responsive';
import CustomerMaster from './CustomerMaster';
import './css/InwardAndBillingForm.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { FaShoppingCart } from 'react-icons/fa';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

//above code is to import libs
function InwardAndBillingForm() {

    const url = window.location.href;
    const navigate = useNavigate();
    
    if(url == "https://asttk-frontend.onrender.com/") {
       window.location.assign('https://laundrymaster.online/');
    }

    const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
        
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
          if(response.data == "logout") {
            navigatetologin();
          }
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Initial fetch if usernameglobal is available
      if (usernameglobal) {
        fetchData();
      }

      // Set up interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [usernameglobal]); // Dependency array includes usernameglobal

    useEffect(() => {
      if (usernameglobal === null) {
        navigate('/LoginPage');
      } else {
        // Reset last activity time whenever there's user interaction
        const handleUserActivity = () => {
          setLastActivityTime(Date.now());
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); // For touch devices

        return () => {
          window.removeEventListener('mousemove', handleUserActivity);
          window.removeEventListener('keydown', handleUserActivity);
          window.removeEventListener('touchstart', handleUserActivity);
        };
      }
    }, [usernameglobal, navigate]);

    useEffect(() => {
      const sessionTimeout = setTimeout(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;

        if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
          console.log('Logging out due to inactivity...');
          navigatetologin();
        } else {
          // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
          sessionTimeout.refresh();
        }
      }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

      return () => clearTimeout(sessionTimeout);
    }, [lastActivityTime, navigatetologin]);

    // console.log(UserMasterDataGlobal[0].usertype);
    async function navigatetologin() {
      const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
      localStorage.removeItem('username');
      localStorage.removeItem('localstorage_id');
      navigate('/LoginPage');
      
    }

    //setting default center value for first option in selectbox
    const [defaultcenter, setdefaultcenter] = useState();

    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' }); // Adjust breakpoint as needed

    const [formData, setFormData] = useState({
        //for centerno inputbox
        jobno: '',
        //for centerid(centername) selectbox
        jobdate: '',
        //date input box initial value should be today's date
        transdate : '',
        customer_id: '',
        city: '',
        customer_mobile: '',
        opbal: '',
        customer_name: '',
        //below code is to show the value of prevbalrs in its label
        prevbalrs: '',
        //if not needed below 'amt' declaration remove it.
        qty: '',
        amt: '',
        // below variable is to show balance value at above label of save button
        balance: '',
        //for cash_recd inputbox
        cash_recd: '',
        //for bank_recd inputbox
        bank_recd: '',
        // the amount of totalbill + previous balance
        totpay: '',
        operationtype: 'Save',
        deliveryon:'',
        status:'',
        tagno:'',
        customer_name_register: '',
        customer_mobile_register: '',
    });

    //this function is to handle the properties in formdata array if values changes in inputbox, selectbox
    const handleInputChange = (e) => {
        
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value,
        };

        if(name == "customer_name") {
            const [_id, customer_name] = value.split('-');
            const foundArray = customer_name_data.find(item => item.customer_name === customer_name);

            if(foundArray != undefined){ 
                updatedFormData.customer_mobile = foundArray.customer_mobile;
                updatedFormData.city = foundArray.city;
                updatedFormData.prevbalrs = foundArray.clbal;
            }
            else {
                updatedFormData.customer_mobile = '';
                updatedFormData.city = '';
                updatedFormData.prevbalrs = '';
            }         
        }
        setFormData(updatedFormData);
    }

    // useEffect(() => {
    //     // Update the date in the formData when the component mounts
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         transdate : getCurrentDate(),
    //     }));
    // }, []);     

    useEffect(() => {
        // Calculate delivery date 5 days after transdate
        if (formData.transdate) {
            calculateDeliveryDate(formData.transdate);
        }
    }, [formData.transdate]);

    // Function to calculate delivery date 5 days after transdate
    const calculateDeliveryDate = (transdate) => {
        const deliveryDate = new Date(transdate);
        deliveryDate.setDate(deliveryDate.getDate() + 5);

        setFormData((prevData) => ({
            ...prevData,
            deliveryon : deliveryDate.toISOString().slice(0, 10) + "T00:00",
        }));
    };

    //below function is to get current date in yyyy-mm-dd format.
    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    //setting errors array
    const [errors,  seterrors] =  useState({});
    
    //setting array 'customer_name_data' to populate list with customer names in its selectbox
    const [customer_name_data,  setcustomer_name_data] =  useState([]);
    useEffect(() => {
        if(UserMasterDataGlobal?.[0]?.Companyid){
            const data = {
                Companyid: UserMasterDataGlobal?.[0]?.Companyid,
                userid: UserMasterDataGlobal?.[0]?._id,
                username: usernameglobal,
            };
            axios.get(process.env.REACT_APP_SERVER_API_URL+`CustomerMaster/show`, { params: data })
            .then((response) => {
                setcustomer_name_data(response.data);
                
                if (response.data.length > 0) {
                    setFormData({
                        ...formData,
                        transdate : getCurrentDate(),
                    });

                }

            })    
            .catch((error) => console.error('Error fetching data:', error));
        }
    }, [UserMasterDataGlobal]);

    const [ClothMasterData, setClothMasterData] = useState([]);
    //fetching the data from cloth_master_tables
    useEffect(() => {
        // Fetch data from the specified URL
        if (ClothMasterData.length == 0 ) {
            axios.get(process.env.REACT_APP_SERVER_API_URL+`ClothMaster/show`)
            .then((response) => {
            // Access the response data directly using response.data
            setClothMasterData(response.data);
            const initialClothData = response.data.map((p) => ({
                ...p,
                qty: 0,
                amt: 0,
            }));
    
            setClothData(initialClothData);
        })
    
        .catch((error) => console.error('Error fetching data:', error));
        }
    }, [ClothMasterData]);
    
    //i don't understand this code now at 21 02 2024 at 12 : 20 PM
    //when you get for what below code is  used? then write it...
    const [clothData, setClothData] = useState([]);

    //below ref declaration is required for qty inputboxes in table
    const qtyInputRefs = useRef([]);

    //below ref declaration is required for qty inputboxes in table
    const charges_rsInputRefs = useRef([]);

    //below totalAmount variable mounting code is to set billamt label's value
    const [totalAmount, setTotalAmount] = useState(0);

    // const handleQtyChange = (index, event) => {
    
    //     setClothData((prevClothData) => {
    //         const updatedClothData = [...prevClothData];
    //         const qty = parseInt(event.target.value, 10) || 0;
    //         updatedClothData[index].qty = qty;
    //         updatedClothData[index].amt = qty * updatedClothData[index].charges_rs;
        
    //         const newTotalAmount = updatedClothData.reduce((sum, cloth) => sum + cloth.amt, 0);
    //         setTotalAmount(newTotalAmount);
      
    //         return updatedClothData;
    //     });            
    // };
    const handleQtyChange = (clothId, event, ev = null) => {
        ev.preventDefault();
        if(event == "passed from card") {
            event = ev;
        }
        setClothData((prevClothData) => {
            const updatedClothData = [...prevClothData];
            const clothIndex = updatedClothData.findIndex(cloth => cloth._id === clothId);
            if (clothIndex !== -1) {
                const qty = parseInt(event.target.value, 10) || 0;
                updatedClothData[clothIndex].qty = qty;
                updatedClothData[clothIndex].amt = qty * updatedClothData[clothIndex].charges_rs;
    
                const newTotalAmount = updatedClothData.reduce((sum, cloth) => sum + cloth.amt, 0);
                setTotalAmount(newTotalAmount);
            }
            return updatedClothData;
        });            
    };

    const handleCharges_rsChange = (clothId, event) => {
        setClothData((prevClothData) => {
            const updatedClothData = [...prevClothData];
            const clothIndex = updatedClothData.findIndex(cloth => cloth._id === clothId);
            if (clothIndex !== -1) {
                const charges_rs = parseInt(event.target.value, 10) || 0;
                updatedClothData[clothIndex].charges_rs = charges_rs;                
                updatedClothData[clothIndex].amt = updatedClothData[clothIndex].qty * updatedClothData[clothIndex].charges_rs;
    
                const newTotalAmount = updatedClothData.reduce((sum, cloth) => sum + cloth.amt, 0);
                setTotalAmount(newTotalAmount);
            }
            return updatedClothData;
        });            
    };
    
    const handleInputDoubleClick = (id) => {
        charges_rsInputRefs.current[id].readOnly = false; // Make the input editable
        charges_rsInputRefs.current[id].focus(); // Focus on the input
    };
    
    function calculate_balance() {
        var calc1 =
            Number(totalAmount) +
            Number(formData.prevbalrs);

        var calc2 = Number(formData.cash_recd) +
                    Number(formData.bank_recd);

        var calc3 = calc1 - calc2;

        setFormData({
            ...formData,
            balance: calc3,
        });
    }

    //for saving the sales entry we use this function
    const handleSubmit = async (event) => {

        event.preventDefault();
        const formData = new FormData(event.target);
        // const [_id, customerName] = formData.get("customer_name").split('-');

        var cramtsummation;
        var customer_transaction_id;
        var validationerrors = {};

        cramtsummation = Number(formData.get("cash_recd")) + Number(formData.get("bank_recd"))

        seterrors(validationerrors);

        var calc1 =
            Number(totalAmount) +
            Number(formData.get('prevbalrs'));

        var calc2 = Number(formData.get('cash_recd')) +
                    Number(formData.get('bank_recd'));
        var calc3 = calc1 - calc2; 

        var data = {
            Companyid: UserMasterDataGlobal?.[0]?.Companyid,
            userid: UserMasterDataGlobal?.[0]?._id,
            tran_type: formData.get('tagno') !== '' ? 'Inward' : 'Receipt',
            tran_date: formData.get('transdate'),
            customer_id: formData.get('customer_id'),
            customer_name: formData.get('customer_name'),
            city: formData.get('city'),
            customer_mobile: formData.get('customer_mobile'),
            routename: 1,
            dramt: formData.get('billamt'),
            cramt: cramtsummation,
            balance: calc3,
            cashRecd: formData.get('cash_recd'), 
            bankRecd: formData.get('bank_recd'),
            transid: formData.get('transid'),
            operationtype: formData.get('operationtype'),
            tagno: formData.get('tagno'),
            // status: formData.get('status'),
            status: 'In Process',
            deliveryon: formData.get('deliveryon'),
        };

        if(data.tran_type == "Receipt" && Number(data.dramt) !== 0) {
            MySwal.fire({
                html: <b>If you intend to register this entry as a receipt, kindly ensure the exclusion of any clothing entries prior to saving. Otherwise, please proceed by entering the corresponding tag number.</b>,
                icon: 'warning'
            })  
            return;               
        }
        else if(data.tran_type == "Inward"){
            if(Number(data.tagno) > 0) {
            }
            else{
                MySwal.fire({
                    html: <b>Please enter a valid tag no</b>,
                    icon: 'warning'
                })  
                return;   
            }
            if(Number(data.dramt) == 0) {
                MySwal.fire({
                    html: <b>kindly ensure the inclusion of any clothing entries prior to saving</b>,
                    icon: 'warning'
                })  
                return;   
            }
        }
        // console.log(data);
        // return;
        const response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerTransactionMaster/insert', data);

        if (response.status === 200) {
            if(response.data.message == "tag present") {
                MySwal.fire({
                    html: <b>Tag already used for transaction. Try using  a different Tag.</b>,
                    icon: 'warning'
                })  
                return;                        
            }
            else{
                customer_transaction_id = response.data.customer_transaction._id;                

                if(data.tran_type == "Receipt") {
                    MySwal.fire({
                        html: <b>Record Saved Sucessfully!</b>,
                        icon: 'success'
                    })
                    emptyinputs();
                    return;
                }
            }
        }
        
        const saveClothData = async (clothData) => {
            try {
                const response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerTransactionMaster/insertsales', clothData);
            
                if (response.status === 200) {
                    //console.log(response.data);
                }
            } catch (error) {
                console.error('Error saving product data:', error);
            }
        };  
        
        clothData.forEach(async (cloth) => {
            const _id = cloth._id; // Get the cloth ID

            const clothData2 = {
                transid: customer_transaction_id,
                tran_date: data.tran_date,
                Companyid: data.Companyid,
                userid: data.userid,
                cloth_id: _id,
                cloth_type: cloth.cloth_type,
                charges_rs: cloth.charges_rs,
                qty: cloth.qty,
                amt: cloth.amt,
            };
            
            // Check if amt is not 0
            if (clothData2.amt !== 0) { // Use strict comparison to avoid type coercion                
                await saveClothData(clothData2);
            }
        });

        MySwal.fire({
            //   title: <strong>Record Saved Sucessfully!</strong>,
            html: <b>Record Saved Sucessfully!</b>,
            icon: 'success'
        })
        emptyinputs();

        if(isCheckedSaveandWhatsapp) {

            let clothArray = []; // Initialize array to hold cloth data
            
            clothData.forEach(async (cloth) => {
                const _id1 = cloth._id; // Get the cloth ID

                const clothData1 = {
                    cloth_type: cloth.cloth_type,
                    charges_rs: cloth.charges_rs,
                    qty: cloth.qty,
                    amt: cloth.amt,
                };
                
                // Create formatted string for each cloth
                if (clothData1.amt !== 0) {                    
                    const clothString = `(${clothData1.cloth_type}, Qty - ${clothData1.qty}, Amount - ${clothData1.amt})`;
                    // Add clothString to the clothArray
                    clothArray.push(clothString);
                }
            });
            
            // Join clothArray with '\n' to separate each cloth
            const clothText = clothArray.join();

            // const message = `
            //     *Rajesh Laundry, Karad*
            //     Inward Date: ${new Date(data.tran_date).toLocaleDateString('en-GB')}
            //     Tag No: ${data.tagno}
            //     Scheduled Delivery on: ${new Date(data.deliveryon).toLocaleDateString('en-GB')}
            //     Bill Amount: ${data.dramt}
            //     Received Rs: ${data.cramt}
            //     Balance Rs: ${data.balance}
            //     Cloths:
            //     ${clothText}
            // `;            

     
        // Generate a unique bill ID
        const generateBillId = (companyId, transactionId) => {
            let billId = '';
            const minLength = Math.min(companyId.length, transactionId.length);
            for (let i = 0; i < minLength; i++) {
                billId += companyId[i] + transactionId[i];
            }
            return billId;
        };

        const billid = generateBillId(data.Companyid, customer_transaction_id);
        const billUrl = `Please click on attached link to see detailed bill - https://laundrymaster.online/Bill/${billid}`;
            

            let whatsappUrl = '';
            if (isDesktop) {
                whatsappUrl = 'https://web.whatsapp.com/send/?phone=' + data.customer_mobile + '&text=' + encodeURIComponent(billUrl);
            } else {
                whatsappUrl = 'https://wa.me/91' + data.customer_mobile + '?text=' + encodeURIComponent(billUrl);
            }
                        
            window.open(whatsappUrl, '_blank');             
            
            // const printWindow = window.open('', '_blank');   
            // printWindow.document.write(`
            //     <!DOCTYPE html>
            //     <html lang="en">
            //     <head>
            //         <meta charset="UTF-8">
            //         <meta name="viewport" content="width=device-width, initial-scale=1.0">
            //         <title>Rajesh Laundry Bill</title>
            //         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
            //     </head>
            //     <body>
            //         <div class="container">
            //             <div class="row justify-content-center">
            //                 <div class="col-md-8">
            //                     <div class="card">
            //                         <div class="card-header">
            //                             <h5 class="card-title" style="text-align: center;">राजेश Power Laundry</h5>
            //                         </div>
            //                         <div class="card-body">
            //                             <div class="row">
            //                                 <div class="col-6">
            //                                     <p>Tag No. ${data.tagno}</p>
            //                                 </div>
            //                                 <div class="col-6 text-end">
            //                                     <p>Date: ${new Date(data.tran_date).toLocaleDateString('en-GB')}</p>
            //                                 </div>
            //                             </div>
            //                             <hr>
            //                             <table class="table table-sm">
            //                                 <thead>
            //                                     <tr>
            //                                         <th>Item Name</th>
            //                                         <th style="text-align: center;">Qty</th>
            //                                         <th style="text-align: center;">Price (₹)</th>
            //                                         <th style="text-align: center;">Amount (₹)</th>
            //                                     </tr>
            //                                 </thead>
            //                                 <tbody>
            //                                 ${clothData.filter(cloth => cloth.qty > 0).map(cloth => `
            //                                     <tr>
            //                                         <td>${cloth.cloth_type}</td>
            //                                         <td style="text-align: center;">${cloth.qty}</td>
            //                                         <td style="text-align: center;">${cloth.charges_rs}</td>
            //                                         <td style="text-align: center;">${cloth.amt}</td>
            //                                     </tr>
            //                                 `).join('')}
            //                                     <tr>
            //                                         <td><b>Total Items:</b></td>
            //                                         <td style="text-align: center;"><b>${clothData.reduce((total, cloth) => total + cloth.qty, 0)}</b></td>
            //                                         <td></td>
            //                                         <td></td>
            //                                     </tr>
            //                                 </tbody>
            //                             </table>
            //                             <hr>
            //                             <table class="table">
            //                                 <tr>
            //                                     <td>Delivery Date: ${new Date(data.deliveryon).toLocaleDateString('en-GB')}</td>
            //                                     <td>Total Amount: ₹${data.dramt}</td>
            //                                 </tr>
            //                                 <tr>
            //                                     <td>Jama Anit (Advance Payment): ₹00</td>
            //                                     <td>Bal (Balance): ₹${data.balance}</td>
            //                                 </tr>
            //                             </table>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //         <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
            //     </body>
            //     </html>
            // `);

            // printWindow.document.close();
            // printWindow.print();

        }
    }  
    
    //declaring the sweet alert constant
    const MySwal = withReactContent(Swal)

    //below code is to emptying the input after insertion of record
    function emptyinputs(){

        setFormData({
            Companyid: UserMasterDataGlobal?.[0]?.Companyid,
            userid: UserMasterDataGlobal?.[0]?._id,
            customer_mp_id: '',
            customer_centerwiseid: '',
            customer_name: '',
            customer_place: '',
            customer_mobile: '',
            prevbalrs: '',
            totpay: '',
            cash_recd: '',
            bank_recd: '',
            balance: '',
            opbal: '',
            tagno: '',
            status: '',
            customer_name: '',
        });
    
        qtyInputRefs.current.forEach((inputRef) => {
          inputRef.value = ''; // or set it to 0 if you prefer
        });
    
        setTotalAmount(0);
    
        setClothData((prevClothData) => {
          return prevClothData.map((cloth) => ({
            ...cloth,
            qty: 0,
            amt: 0,
          }));
        });
    }

    const [isCheckedSaveandWhatsapp, setisCheckedSaveandWhatsapp] = useState(false);
    const handleCheckboxChange = () => {
        setisCheckedSaveandWhatsapp(!isCheckedSaveandWhatsapp);
    };
    
    const [isAdd_Customer_Visible, setisAdd_Customer_Visible] = useState(false);
    const Add_Customer_Visible_Invisible = (event) => {
        event.preventDefault();
        setisAdd_Customer_Visible(!isAdd_Customer_Visible);
    };

    async function SaveNewCustomer(event) {
        event.preventDefault();

        if(formData.customer_name_register == "") {
            alert("Please enter customer name value");
            return;
        }
       
        var data = {
            Companyid: UserMasterDataGlobal?.[0]?.Companyid,
            userid: UserMasterDataGlobal?.[0]?._id,
            customer_name: formData.customer_name_register.trim(),
            customer_mobile:formData.customer_mobile_register.trim(),
            address:'',
            city:'',
            opbal:0,
            clbal:0,
        };

        const response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerMaster/insert', data); // Correct the URL format
        
        if(response.data.message == "Data saved successfully"){
            MySwal.fire({
                html: <b>Record Saved Sucessfully!</b>,
                icon: 'success'
            })
            
            // Get the customer ID from the response data
            const customerId = response.data.customer._id;

            // Update the data object with the customer ID
            const newData = {
                ...data,
                _id: customerId
            };

            // Update state by pushing the new data into the existing array
            setcustomer_name_data(prevData => [...prevData, newData]);

            // Update the form data state with the customer ID and reset other fields
            setFormData({
                ...formData,
                customer_id: customerId,
                customer_name_register : '',
                customer_mobile_register : '',
            });

            setisAdd_Customer_Visible(!isAdd_Customer_Visible);
        }                     
    }

    //below code is used to place customer_centerwiseid of selected customer from datalist in its inputbox.
    // Select event handler for customer_name
    const handleDatalistSelect = (e) => {
        const selectedOption = e.target.value;
        const selectedCustomer = customer_name_data.find((option) => option.customer_name === selectedOption);
        if (selectedCustomer) {
        setFormData((prevData) => ({
            ...prevData,
            customer_id: selectedCustomer._id,
            customer_mobile: selectedCustomer.customer_mobile,
            city: selectedCustomer.city,
            prevbalrs: selectedCustomer.clbal,
        })); 
        }
    };

    const [filteredCustomerData, setFilteredCustomerData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
  
    useEffect(() => {
        // Initialize filtered data with all cloth items
        setFilteredCustomerData(ClothMasterData);
    }, [ClothMasterData]);    
  
    function handleSearchInputChange(event) {
      setSearchKeyword(event.target.value);
      filterCustomerData(event.target.value);
    }
  
    function filterCustomerData(keyword) {
        const filteredData = ClothMasterData.filter(cloth => (
          cloth.cloth_type.toLowerCase().includes(keyword.toLowerCase())
        ));
      
        // Add the following lines to populate qty from original data
        const updatedFilteredData = filteredData.map(filteredCloth => {
          const originalCloth = ClothMasterData.find(cloth => cloth._id === filteredCloth._id);
          if (originalCloth) {
            filteredCloth.qty = originalCloth.qty;
          }
          return filteredCloth;
        });
      
        setFilteredCustomerData(updatedFilteredData);
    }
      
    const getCardColumns = () => {
        if (window.innerWidth >= 1260) {
            // Large screens (desktops): 3 cards in a row
            return 4;
        }
        if (window.innerWidth >= 1090) {
            // Large screens (desktops): 3 cards in a row
            return 3;
        }
        else if (window.innerWidth >= 600) {
            // Medium screens (tablets): 2 cards in a row
            return 2;
        } else {
            // Small screens (phones): 1 card in a row
            return 1;
        }
    };

    const handleSaveAndWhatsappClick = () => {
        setisCheckedSaveandWhatsapp(true);
    };

    const handleSaveClick = () => {
        setisCheckedSaveandWhatsapp(false);
    };

    const [showBillingPopup, setShowBillingPopup] = useState(false);
    
    const toggleBillingPopup = (event) => {
        event.preventDefault();
        setShowBillingPopup(!showBillingPopup);
    };
    
    const popupRef = useRef(null);

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setShowBillingPopup(false);
        }
    };

    useEffect(() => {
        if (showBillingPopup) {
            document.addEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'hidden'; // Prevent scrolling on background
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'auto'; // Allow scrolling on background
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'auto'; // Ensure scrolling is restored when component unmounts
        };
    }, [showBillingPopup]);

    return (
        <>
        {/* <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Inward and Billing Form </h1> */}
        <NavBarMain />
        <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
        <form onSubmit={handleSubmit} method="POST" id="form1">
            <div className="row" styles="background-color:lightgreen;">
                <div className="row mb-3">
                    <div className="col-md-2">
                        <label htmlFor="id-transdate" className="form-label">Job Date</label>
                        <input type="date" className="form-control" name="transdate" id="id-transdate"  value={formData.transdate}  onChange={handleInputChange} required/>
                    </div>

                    <div className="col-md-2">
                        <label htmlFor="customer_name" className="form-label">Select Customer</label>
                        {/* <select
                            className="form-select"
                            name="customer_name"    
                            onChange={handleInputChange}                            
                            >
                            {Array.isArray(customer_name_data) && customer_name_data.map((option) => ( 
                                <option key={option._id}  value={`${option._id}-${option.customer_name}`} >{option.customer_name}</option>
                            ))}
                        </select>   */}

                        <input
                        type='text'
                        name='customer_name'                      
                        list='customer_name_list'
                        className='form-control'
                        value={formData.customer_name}
                        onChange={handleInputChange}
                        onSelect={handleDatalistSelect}
                        autoComplete='off' //to not show cache data in inputbox
                        required
                        />
                        <datalist id="customer_name_list" >
                            {Array.isArray(customer_name_data) && customer_name_data.map((option) => ( 
                                <option key={option._id}  value={`${option.customer_name}`} ></option>
                            ))}
                        </datalist>

                        <input type='hidden' name="customer_id" value={formData.customer_id} onChange={handleInputChange} />
                        <input type='hidden' name="customer_mobile" value={formData.customer_mobile} onChange={handleInputChange} />
                        <input type='hidden' name="city" value={formData.city} onChange={handleInputChange} />
                        <input type='hidden' name="operationtype" value={formData.operationtype} onChange={handleInputChange} />
                        {formData.customer_mobile !== "" && <span style={{color: 'blue', fontSize: '14px', textAlign: 'center', marginLeft: '13px'}}>Mobile No: {formData.customer_mobile}</span>}
                    </div>
                    
                    <div className="col-12 col-md-2 Add-Customer" style={{fontSize: 15, marginTop:30 }}>
                        <button type="submit" className='btn btn-primary' onClick={Add_Customer_Visible_Invisible}>Add Customer Instantly</button>
                    </div>
                    
                    {isAdd_Customer_Visible && (
                    <>
                    <div className="col-12 col-md-2 customer_name_register">
                        <label htmlFor="customer_name_register" className="form-label">Customer Name</label>
                        <input type="text" className="form-control" name="customer_name_register" id="id-customer_name_register"  value={formData.customer_name_register}  onChange={handleInputChange} />
                    </div>

                    <div className="col-md-2">
                        <label htmlFor="customer_mobile_register" className="form-label">Mobile No</label>
                        <input type="number" className="form-control" name="customer_mobile_register" id="id-customer_mobile_register"  value={formData.customer_mobile_register}  onChange={handleInputChange} />
                    </div>

                    <div className="col-md-2">
                        <label htmlFor="Save" className="form-label" style={{color: 'white'}}>Save</label>
                        <button type="submit" className='btn btn-primary w-100' onClick={SaveNewCustomer}>Save</button>
                    </div>
                    </>
                    )}
                </div>                                     
                
                <div className="row mb-3">
                    <div className="col-md-9">
                        <div className="card h-100" style={{borderColor:"rgb(66, 41, 203)", borderWidth:"2px", boxShadow: "10px 6px 9px rgb(62, 62, 62)", overflowY: 'hidden'}}>
                        <h5 className="card-title" style={{textAlign: 'center',background:"rgb(66, 41, 203)",color:"white", padding: 5}}>Inward</h5> 
                            
                            {/* <div className={`col-md-${12 / getCardColumns()}`} style={{marginTop: 5, margin:10}}>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder="Search..."
                                    value={searchKeyword}
                                    style={{border: '1px solid black'}}
                                    onChange={handleSearchInputChange}
                                />
                            </div> */}

                            <div className={`col-md-${12 / getCardColumns()}`} style={{marginTop: 5, margin:10}}>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder="Search..."
                                    list='cloth_type_list'
                                    value={searchKeyword}
                                    style={{border: '1px solid black'}}
                                    onChange={handleSearchInputChange}
                                    onSelect={handleSearchInputChange}
                                />

                                <datalist id="cloth_type_list" >
                                    {Array.isArray(ClothMasterData) && ClothMasterData.map((option) => ( 
                                        <option key={option._id}  value={`${option.cloth_type}`} ></option>
                                    ))}
                                </datalist>
                            </div>

                            <div className="row" style={{overflow: 'auto', height: '400px'}}>
                                {filteredCustomerData.map((p, index) => (
                                    
                                    <div className={`col-md-${12 / getCardColumns()}`} key={p._id}>
                                    <Card style={{ marginBottom: 10, margin: 10, border: '1px solid black', boxShadow: 'rgb(62, 62, 62) 10px 6px 9px' }}>
                                    <div style={{ height: '150px', overflow: 'hidden' }}>
                                        <Card.Img
                                            variant="top"
                                            src={p.cloth_img}
                                            alt={`Image for ${p.cloth_type}`}
                                            style={{ width: '100%', objectFit: 'cover' }} // Ensure the image covers the entire card body
                                        />
                                    </div>
                                    <Card.Body>
                                        <Card.Title>{p.cloth_type}</Card.Title>
                                        <Card.Text>
                                            {/* {p.charges_rs} */}

                                            Rate <span style={{fontSize: 20}}>&#8377;</span>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name={`charges_rs[${p._id}]`}
                                                value={clothData.find(data => data._id === p._id)?.charges_rs || ''}
                                                style={{ width: '125px', float: 'right', textAlign: 'right', border: '1px solid black' }}
                                                ref={(input) => (charges_rsInputRefs.current[p._id] = input)}
                                                onChange={(event) => handleCharges_rsChange(p._id, event)}
                                                onDoubleClick={() => handleInputDoubleClick(p._id)}
                                                readOnly // Set initially as readOnly
                                            />   

                                        </Card.Text>
                                        <Card.Text>
                                            Qty
                                            <input
                                                type="number"
                                                className="form-control"
                                                name={`qty[${p._id}]`}
                                                value={clothData.find(data => data._id === p._id)?.qty || ''}
                                                style={{ width: '125px', float: 'right', textAlign: 'right', border: '1px solid black' }}
                                                ref={(input) => (qtyInputRefs.current[p._id] = input)}
                                                onChange={(event) => handleQtyChange(p._id, "passed from card", event)}
                                            />                                            
                                        </Card.Text>
                                        <Card.Text style={{ marginTop: 5 }}>
                                            <a style={{ float: 'right' }}>Amount <span style={{fontSize: 20}}>&#8377;</span>: {clothData.find(data => data._id === p._id)?.amt || '0'}</a>
                                        </Card.Text>
                                        <input
                                            type="hidden"
                                            style={{ fontSize: '20px' }}
                                            name={`amt[${p._id}]`}
                                            className="form-control"
                                            value={clothData.find(data => data._id === p._id)?.amt || 0}
                                            onChange={handleInputChange}
                                            readOnly
                                        />
                                    </Card.Body>
                                </Card>
                            </div>

                                ))}
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-3">
                        <div className="card h-100" style={{ borderColor: "rgb(66, 41, 203)", borderWidth: "2px", boxShadow: "10px 6px 9px rgb(62, 62, 62)" }}>
                            <h5 className="card-title" style={{ textAlign: 'center', background: "rgb(66, 41, 203)", color: "white", padding: 5 }}>Cart <FaShoppingCart style={{ marginRight: 5 }} /></h5>
                            <div className="card-body" style={{ overflowY: 'auto', height: '488px', marginTop: -10 }}>
                                {clothData.filter(cloth => cloth.qty > 0).map(cloth => (
                                    <div key={cloth._id} style={{ marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15 }}>
                                            <strong>{cloth.cloth_type}</strong>
                                            
                                            <div className="input-group" style={{ marginLeft: 'auto', maxWidth: '50%' }}>
                                                <button
                                                    className="btn btn-sm btn-outline-primary"
                                                    onClick={(event) => handleQtyChange(cloth._id, { target: { value: cloth.qty - 1 } }, event)}
                                                    disabled={cloth.qty <= 0}
                                                    style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
                                                >
                                                    -
                                                </button>
                                                <input
                                                    type="text"
                                                    value={cloth.qty}
                                                    className="form-control col-md-1"
                                                    style={{ width: '10px', textAlign: 'center', borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderLeft: 'none', borderRight: 'none' }}
                                                    readOnly
                                                />
                                                <button
                                                    className="btn btn-sm btn-outline-primary"
                                                    onClick={(event) => handleQtyChange(cloth._id, { target: { value: cloth.qty + 1 } }, event)}
                                                    style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
                                                >
                                                    +
                                                </button>
                                                
                                            </div>
                                            
                                        </div>

                                        <span>Rate: <span style={{ fontSize: 20 }}>&#8377;</span>{cloth.charges_rs}</span>
                                        <br />
                                        <span>Amount: <span style={{ fontSize: 20 }}>&#8377;</span>{cloth.amt}</span>
                                        <button className='btn btn-danger' onClick={(event) => handleQtyChange(cloth._id, { target: { value: cloth.qty = 0 } }, event)} style={{float: 'right', marginLeft: 'auto', maxWidth: '50%'}}>Delete</button>
                                    </div>
                                ))}
                            </div>
                            <button type="submit" onClick={toggleBillingPopup} className='btn btn-primary' style={{float: 'right', zIndex: 1, position: 'sticky', marginBottom: '2px', borderBottom: '1px solid #ccc'}}>Check Bill</button>
                        </div>
                    </div>

                    {showBillingPopup && (
                     <div className="overlay" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
                    <div ref={popupRef} className="col-md-4" style={{ position: 'fixed', top: '50px', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                        <div className="card h-100" style={{ borderColor: "rgb(66, 41, 203)", borderWidth: "2px", boxShadow: "10px 6px 9px rgb(62, 62, 62)" }}>
                            <h5 className="card-title" style={{ textAlign: 'center', background: "rgb(66, 41, 203)", color: "white", padding: 5 }}>Billing<span style={{float: 'right', cursor: 'pointer'}} onClick={() => setShowBillingPopup(false)}>X</span></h5>
                            <div className="card-body" style={{ display: 'flex', flexDirection: 'column', height: '488px', marginTop: -10 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15 }}>
                                <span>Delivery on:</span>
                                <input type='datetime-local' style={{ width: '60%' }} name="deliveryon" className="form-control" value={formData.deliveryon} onChange={handleInputChange} required></input>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop: 5 }}>
                                <span>Tag No:</span>
                                <input type='number' style={{ width: '40%' }} className="form-control" name="tagno" value={formData.tagno} onChange={handleInputChange}></input>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15 }}>
                                <span>Bill Amount <span style={{ fontSize: 20 }}>&#8377;</span>:</span>
                                <span style={{ fontSize: "30px" }}>{totalAmount}</span>
                                <input type='hidden' name="billamt" className="form-control" value={(Number(totalAmount)).toFixed(2)} readOnly></input>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop: 15 }}>
                                <span>Previous Balance <span style={{ fontSize: 20 }}>&#8377;</span>:</span>
                                <span style={{ fontSize: "20px", color: "red" }}>{Number(formData.prevbalrs).toFixed(2)}</span>
                                <input type='hidden' name="prevbalrs" className="form-control" value={(Number(formData.prevbalrs).toFixed(2))} onChange={handleInputChange} readOnly></input>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop: 15 }}>
                                <span>Total Amount <span style={{ fontSize: 20 }}>&#8377;</span>:</span>
                                <span>{((Number(formData.prevbalrs) + Number(totalAmount)).toFixed(2))} </span>
                                <input type='hidden' name="totpay" className="form-control" value={((Number(formData.prevbalrs) + Number(totalAmount)).toFixed(2))} onChange={handleInputChange} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop: 15 }}>
                                <span>Cash Payment <span style={{ fontSize: 20 }}>&#8377;</span>:</span>
                                <input type="number" className="form-control" name="cash_recd" style={{ width: '40%' }} onBlur={calculate_balance} value={formData.cash_recd} onChange={handleInputChange} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop: 15 }}>
                                <span>Online <span style={{ fontSize: 20 }}>&#8377;</span>:</span>
                                <input type="number" className="form-control" style={{ width: '40%' }} name="bank_recd" onBlur={calculate_balance} value={formData.bank_recd} onChange={handleInputChange} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop: 15 }}>
                                <span>Balance Remained <span style={{ fontSize: 20 }}>&#8377;</span>:</span>
                                <span>{Number(formData.balance).toFixed(2)}</span>
                                <input type='hidden' name="balance" value={Number(formData.balance).toFixed(2)} onChange={handleInputChange} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 15, marginTop: 15 }}>
                                <input className="form-check-input" style={{ display: 'none' }} type="checkbox" checked={isCheckedSaveandWhatsapp} onChange={handleCheckboxChange} name="DeleteAndGenerateAgain" />
                                <button type="submit" onClick={handleSaveAndWhatsappClick} className='btn btn-primary' style={{ float: 'right', zIndex: 1, backgroundColor: '#25D366', color: 'white', borderColor: '#25D366' }}>Save & Whatsapp <FaWhatsapp /></button>
                                <button type="submit" onClick={handleSaveClick} className='btn btn-primary' style={{ float: 'right', zIndex: 1 }}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            )}

                </div>                
            </div>           
        </form>
    </div>
    </>
    );
}

export default InwardAndBillingForm;